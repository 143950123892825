import { MultiUseVariables } from './multi-use-variables';

export function filterDocumentData(data, folderName) {
  const documents = [];
  // if the folder is the moving folder, then format and add signed documents
  if (folderName === 'Moving' && data.signed_documents.length > 0) {
    for (const file of data.signed_documents) {
      file.title = file.document_template.title;
      file.file_name = file.signed_form_url.split('/').pop();
      file.url = file.signed_form_url;
      documents.push(file);
    }
  }

  for (const loopFolderName in data.documents) {
    // if the folder is the files folder, then add all files from "" array
    if (folderName === 'Files' && loopFolderName === '') {
      data.documents[''].forEach((element) => {
        documents.push(element);
      });
    }

    // Get all documents from the folder and add to the array
    if (loopFolderName === folderName) {
      data.documents[loopFolderName].forEach((element) => {
        documents.push(element);
      });
    }
  }

  return documents;
}

export function urlify(text) {
  const urlRegex = /(https?:\/\/[^\s]+)/g;
  return text.replace(urlRegex, (url) => {
    return `<a href="${url}" target=_blank class="white-link">${url}</a>`;
  });
}

export function getFileIcon(file_url) {
  const vars = MultiUseVariables;
  const docExtensions = vars.docExtensions;
  const imageExtensions = vars.imageExtensions;
  const videoExtensions = vars.videoExtensions;

  if (file_url === '' || !file_url) {
    return 'minus-square/grey.svg';
  } else {
    file_url = file_url.trim().toLowerCase();
    const extension = `.${file_url.split('.').pop()}`.trim();
    if (extension === '.pdf') {
      return 'pdf/blue.svg';
    }
    if (videoExtensions.includes(extension)) {
      return 'video-camera/blue.svg';
    }
    if (docExtensions.includes(extension)) {
      return 'file-with-text/blue.svg';
    }
    if (imageExtensions.includes(extension)) {
      return 'picture/blue.svg';
    }
  }
  return 'blue-external-icon.svg';
}

// apply filter on rewards manager page
export function filterToObject(value, object) {
  return value === 'All' ? '' : value;
}

// checks that properties are approved, have a formatted address, and are not archived
export function filterUserProperties(propertiesArray) {
  const filteredProperties = [];
  for (const property of propertiesArray) {
    if (!property.formatted_address && property.property) {
      property.formatted_address = property.property.formatted_address;
    }
    if (property.is_approved === true && property.archived === false) {
      filteredProperties.push(property);
    }
  }
  return filteredProperties;
}
