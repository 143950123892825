import { Injectable } from '@angular/core';

@Injectable()
export class DeviceDetectorService {
  getDeviceOS(): string {
    const userAgent = navigator.userAgent;

    if (/android/i.test(userAgent)) {
      return 'Android';
    }

    if (/iPad|iPhone|iPod/.test(userAgent)) {
      return 'iOS';
    }

    return 'Unknown';
  }
}
