// import statements
import {
  Component,
  ElementRef,
  HostListener,
  OnInit,
  ViewChild,
} from '@angular/core';
import { FormGroup } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import {
  NgbDate,
  NgbDateStruct,
  NgbInputDatepicker,
} from '@ng-bootstrap/ng-bootstrap';
import * as moment from 'moment';
import { IDropdownSettings } from 'ng-multiselect-dropdown/multiselect.model';
import { Address } from 'ngx-google-places-autocomplete/objects/address';
import { SlickCarouselComponent } from 'ngx-slick-carousel';
import { ToastrService } from 'ngx-toastr';
import { interval, Observable, Subscription } from 'rxjs';
import { DataService } from 'src/app/_helpers/data.service';
import swal from 'sweetalert2';
import Utils from '../Elements/utils';

declare global {
  interface Window {
    confetti: any;
  }
}

// class declaration
@Component({
  selector: 'app-user-onboarding',
  templateUrl: './user-onboarding.component.html',
  styleUrls: ['./user-onboarding.component.scss'],
})

// component class declaration
export class UserOnboardingComponent implements OnInit {
  user: any = {};
  is_builder = false;
  innerWidth: any;
  avatarUrl = '';
  text_phone = '';
  validation_error = '';
  showMessage = false;
  message = '';
  privacyAccepted = false;
  subscribed = false;
  formGroup: FormGroup;
  builder_name: any;
  success = false;
  loading = false;
  builder: any = {};
  model: any = {};
  buyer_type: any = '';
  maxDate: any = { year: 2023, month: 1, day: 31 };

  minPossessionDate: any = {
    year: new Date().getFullYear(),
    month: new Date().getMonth() + 1,
    day: new Date().getDate(),
  };

  carouselConfig = {
    slidesToShow: 1,
    slidesToScroll: 1,
    arrows: false,
    dots: false,
    infinite: false,
    variableWidth: true,
    speed: 500,
    cssEase: 'ease',
  };
  pages = [1, 2, 3, 4, 5, 6];
  currentPage = 1;
  pageData: { [key: number]: any } = {};
  dots: number[];

  paused = true;
  pausable: Observable<number>;
  subscription: Subscription;

  isFirstBuyer = true;
  isFirmPossession = true;
  languages: any = [];
  selectedPreferredLanguages: any = '';
  possessionDate: any = {};
  targetPossessionDate: any = '';
  buyerType: any = '';
  hasFirmPossession = false;

  details: any = {};

  iAm: any = [
    { value: 'first-time', label: 'Yes, I am a first time home buyer' },
    { value: 'move-up', label: 'No, I am scaling up' },
    { value: 'move-down', label: 'No, I am downsizing' },
    { value: 'investment', label: 'No, this is an investment property' },
  ];
  selectedIam: any;

  whoIsMoving: any = [
    { value: 'my-self', label: 'Myself' },
    { value: 'family-member', label: 'Family Member' },
    { value: 'tenant', label: 'Tenant' },
  ];
  selectedWhoIsMoving: any;

  areYouSelling: any = [
    { value: 'Yes', label: 'Yes' },
    { value: 'No', label: 'No' },
  ];
  selectedAreYouSelling: any;

  location: any = {};
  placeDetails: any = { latitude: 51.044, longitude: -114.071 };

  servicesList: any = [
    {
      name: 'Insurance',
      label:
        'Receive custom insurance quotes to cover the things that matter the most',
    },
    {
      name: 'Mortgage',
      label:
        'Obtain competitive rate options or a second opinion on your current mortgage',
    },
    {
      name: 'Realtor',
      label:
        'Learn more about home selling solutions that will fit your specific needs ',
    },
    {
      name: 'Legal',
      label:
        'Get connected with an independent lawyer to help finalize your real estate transaction',
    },
    {
      name: 'Moving',
      label: 'I need help planning my move',
    },
  ];

  referredBy: any = [
    { value: 'Yes', label: 'Yes' },
    { value: 'No', label: 'No' },
  ];
  selectedReferredBy: any;

  referredByType: any = [
    { value: 'realtor', label: 'Realtor' },
    { value: 'mortgage-broker', label: 'Mortgage broker' },
    { value: 'friend', label: 'Friend' },
    { value: 'family', label: 'Family' },
    { value: 'colleague', label: 'Colleague' },
    { value: 'other', label: 'Other' },
  ];
  selectedReferredByType: any;

  languagesList: any = [];
  selectedLanguages: any = [];
  selectedLanguagesString = '';
  dropdownSettings: IDropdownSettings = {};

  prefferedContactMedium: any = [
    { value: 'email', label: 'Email' },
    { value: 'call', label: 'Call' },
    { value: 'text-message', label: 'Text message' },
    { value: 'app', label: 'App' },
  ];
  selectedPrefferedContactMedium: any;

  howDidYouHear: any[] = [];
  selectedHowDidYouHear: any;
  selectedHowYouHear: any;

  password: any = '';
  repassword: any = '';
  subscribeCheckbox = false;
  termsAndConditions = false;

  new_user_token: any;
  email: any;
  skipPageTwo = false;
  skipPageThree = false;
  myBuilder = 'My builder';
  months = [
    { label: 'January', value: 1 },
    { label: 'February', value: 2 },
    { label: 'March', value: 3 },
    { label: 'April', value: 4 },
    { label: 'May', value: 5 },
    { label: 'June', value: 6 },
    { label: 'July', value: 7 },
    { label: 'August', value: 8 },
    { label: 'September', value: 9 },
    { label: 'October', value: 10 },
    { label: 'November', value: 11 },
    { label: 'December', value: 12 },
  ];
  years: number[] = [];
  selectedMonth: any;
  selectedYear: any;
  campaignUrl = 'App download email event triggered campaign';

  @ViewChild('passwordInput') passwordInput: ElementRef;
  @ViewChild('repasswordInput') repasswordInput: ElementRef;

  constructor(
    private dataService: DataService,
    private route: ActivatedRoute,
    private router: Router,
    private toastrService: ToastrService
  ) {
    this.onResize();
    const current = new Date();
    const max = new Date();
    max.setDate(current.getDate() + 60);

    this.maxDate = {
      year: max.getFullYear(),
      month: max.getMonth() + 1,
      day: max.getDate(),
    };

    this.dots = Array(5).fill(0);

    const currentYear = new Date().getFullYear();
    for (let i = currentYear; i <= currentYear + 10; i++) {
      this.years.push(i);
    }
  }

  @HostListener('window:resize', ['$event'])
  onResize(event?) {
    this.innerWidth = window.innerWidth;
  }

  ngOnInit() {
    this.is_builder = false;
    this.dropdownSettings = {
      singleSelection: false,
      idField: 'id',
      textField: 'language',
      defaultOpen: false,
      enableCheckAll: false,
      itemsShowLimit: 3,
      allowSearchFilter: true,
    };
    this.getLanguages();
    this.route.paramMap.subscribe((params) => {
      const email = atob(params.get('email'));
      const token = params.get('token');

      this.new_user_token = token;
      this.email = email;
      this.checkNewUserToken(token, email);
      let i = 0;
      const intervalId = setInterval(() => {
        this.shoot();
        i++;
        if (i === 3) {
          clearInterval(intervalId);
        }
      }, 1000);
      this.pausable = interval(800);
      this.subscription = this.pausable.subscribe();
    });
  }

  getActivationData(userId, token) {
    this.dataService
      .getClientOnboardingDetails(userId, token)
      .subscribe((data: any) => {
        if (data.activation_data) {
          this.details.personal_notes = data.activation_data.personal_note;
          this.details.what_is_needed = data.activation_data.what_is_needed
            ? data.activation_data.what_is_needed
            : '';
          const whoIsMoving = data.activation_data.who_is_moving;
          if (whoIsMoving) {
            if (whoIsMoving === 'family' || whoIsMoving === 'joint-family') {
              this.selectedWhoIsMoving = this.whoIsMoving.find(
                (item) => item.value === whoIsMoving
              );
              this.details.who_is_moving = whoIsMoving;
            } else {
              this.selectedWhoIsMoving = this.whoIsMoving.find(
                (item) => item.value === 'others'
              );
              this.details.who_is_moving = whoIsMoving;
            }
          }
          this.details.from_where = data.place_details.formatted_address;
          this.setFirstHomeValue(data);
          this.selectedAreYouSelling = this.areYouSelling.find(
            (item) => item.value === data.activation_data.selling_property
          );
          this.selectedPrefferedContactMedium =
            this.prefferedContactMedium.find(
              (item) => item.value === data.activation_data.preferred_contact
            );
          if (data.activation_data.awareness) {
            const awareness = data.activation_data.awareness;
            const normalisedAwareness = awareness
              .replace('My builder', this.builder_name)
              .replace('my builder', this.builder_name);
            this.selectedHowDidYouHear = this.howDidYouHear.find(
              (item) => item.value === normalisedAwareness
            );
          }
          if (data.activation_data.referred_by === 'No') {
            this.selectedReferredBy = this.referredBy.find(
              (item) => item.value === data.activation_data.referred_by
            );
          } else {
            if (data.activation_data.referred_by) {
              this.selectedReferredBy = this.referredBy.find(
                (item) => item.value === 'Yes'
              );
              this.details.referred_by = data.activation_data.referred_by;
              if (this.selectedReferredBy) {
                this.selectedReferredByType = this.referredByType.find(
                  (item) => item.value === data.activation_data.referred_by_type
                );
              }
            }
          }
          this.selectedWhoIsMoving = this.whoIsMoving.find(
            (item) => item.value === data.activation_data.who_is_moving
          );
          this.selectedMonth = this.months.find(
            (item) => item.value === data.activation_data.possession_month
          );
          this.selectedYear = this.years.find(
            (item) => item === data.activation_data.possession_year
          );
          if (data.activation_data.possible_services) {
            const services = data.activation_data.possible_services.split(', ');
            for (const service of services) {
              const index = this.servicesList.findIndex(
                (item) => item.name === service
              );
              if (index !== -1) {
                this.servicesList[index].selected = true;
              }
            }
          }
          if (data.activation_data.preferred_language) {
            const preferredLanguages =
              data.activation_data.preferred_language.split(', ');

            this.selectedLanguages = this.languagesList.filter((lang) =>
              preferredLanguages.includes(lang.language)
            );
            this.updateSelectedLanguagesString();
          }
          this.hideShowTiles();
        }
        if (data.place_details) {
          this.placeDetails = data.place_details;
          this.details.from_where = data.place_details.formatted_address;
        }
      });
  }

  setFirstHomeValue(data) {
    if (data.activation_data.buyer_type) {
      this.selectedIam = this.iAm.find(
        (item) => item.value === data.activation_data.buyer_type
      );
    } else {
      this.selectedIam = null;
    }
  }

  hideShowTiles() {
    if (this.selectedHowDidYouHear && this.selectedReferredBy) {
      this.skipPageTwo = true;
    }
    if (this.selectedIam && this.selectedMonth && this.selectedYear) {
      if (this.selectedIam.value == 'investment') {
        this.skipPageThree = true;
      } else {
        if (this.selectedWhoIsMoving) {
          this.skipPageThree = true;
        }
      }
    }
    this.updateDots();
  }

  updateDots() {
    this.dots = Array(this.pages.length - 1).fill(0);
    if (this.skipPageTwo) {
      this.dots[1] = 1;
    }
    if (this.skipPageThree) {
      this.dots[2] = 1;
    }
  }

  onSelectMonth(item: any) {
    this.selectedMonth = item;
  }

  onSelectYear(item: any) {
    this.selectedYear = item;
  }

  shoot() {
    try {
      this.confetti({
        angle: this.random(60, 120),
        spread: this.random(10, 50),
        particleCount: this.random(40, 50),
        origin: {
          y: 0.6,
        },
      });
    } catch (e) {
      this.toastrService.error('Something went wrong!');
    }
  }

  random(min: number, max: number) {
    return Math.random() * (max - min) + min;
  }

  confetti(args: any) {
    const confetti = window.confetti;
    return confetti.apply(this, arguments);
  }

  checkNewUserToken(token, email) {
    this.dataService.checkNewUserToken(token, email).subscribe(
      (data: any) => {
        this.user = data.user;
        if (this.user.builder_account) {
          this.builder = this.user.builder_account;
        }
        if (this.user.roles.includes('customer_user')) {
          if (
            this.user.builder_account.logo !== null &&
            this.user.builder_account.logo !== ''
          ) {
            this.avatarUrl = this.user.builder_account.logo;
          } else {
            this.avatarUrl = '/assets/images/logo-icon.png';
          }
          this.builder_name = this.user.builder_account.builder_name;
        } else {
          this.avatarUrl = '/assets/images/logo-icon.png';
        }
        this.showMessage = false;
        this.howDidYouHear = [
          { value: `${this.builder_name} told me directly about Virtuo` },
          { value: `I got a message from ${this.builder_name} about Virtuo` },
          { value: 'I saw information about Virtuo when looking for a home' },
          { value: 'I saw Virtuo in an ad or billboard' },
          { value: 'I have not heard of Virtuo before today' },
        ];
        this.getActivationData(this.user.id, token);
      },
      (err) => {
        this.showMessage = true;
        this.message = 'Invalid URL or URL has expired';
      }
    );
  }

  formatDate(date, format) {
    if (date !== null) {
      const dateObject = moment(date, format).toDate();

      return new NgbDate(
        dateObject.getFullYear(),
        dateObject.getMonth() + 1,
        dateObject.getDate()
      );
    } else {
      return '';
    }
  }

  slideChanged(event: SlickCarouselComponent) {
    this.currentPage = event.currentIndex + 1;
  }

  nextPage() {
    if (this.currentPage < this.pages.length) {
      this.currentPage++;

      if (this.skipPageTwo && this.currentPage === 2) {
        this.currentPage = 3;
      }
      if (this.skipPageThree && this.currentPage === 3) {
        this.currentPage = 4;
      }
      if (this.currentPage === this.pages.length) {
        let i = 0;
        const intervalId = setInterval(() => {
          this.shoot();
          i++;
          if (i === 10) {
            clearInterval(intervalId);
            setTimeout(() => {
              for (let j = 0; j < 10; j++) {
                this.shoot();
              }
            }, 2000);
          }
        }, 700);
      }
      if (this.currentPage === this.pages.length) {
        this.onSubmit();
      }
    }
  }

  previousPage() {
    if (this.currentPage > 1) {
      this.currentPage--;
      if (this.currentPage === 3 && this.skipPageThree) {
        this.currentPage = 2;
      }
      if (this.currentPage === 2 && this.skipPageTwo) {
        this.currentPage = 1;
      }
    }
  }

  onSelectIam(item: any) {
    this.selectedIam = item;
    if (this.selectedIam.value == 'investment') {
      this.details.who_is_moving = '';
      this.selectedWhoIsMoving = null;
      this.selectedAreYouSelling = null;
      this.details.from_where = '';
    }
  }

  onSelectWhoIsMoving(value: string) {
    this.selectedWhoIsMoving = value;
    if (this.selectedWhoIsMoving.value === 'others') {
      this.details.who_is_moving = '';
    } else {
      this.details.who_is_moving = this.selectedWhoIsMoving.value;
    }
  }

  handleAddressChange(place: Address) {
    const place_details = Utils.getLocationDetails(place);
    this.location.address = this.details.from_where;
    delete place_details.display_name;
    this.placeDetails = place_details;
    this.placeDetails.formatted_address = place.formatted_address;
  }

  toggleServicesCheckbox($event, i) {
    this.servicesList[i].selected = $event.checked;
  }

  onSelectReferredBy(item: any) {
    this.selectedReferredBy = item;
    if (this.selectedReferredBy.value != 'Yes') {
      this.details.referred_by = '';
    }
  }

  getLanguages() {
    this.dataService.getLookupLanguages().subscribe((data: any) => {
      this.languagesList = data.languages;
    });
  }

  togglePasswordVisibility(inputField) {
    if (inputField === 'passwordInput') {
      const inputElement = this.passwordInput.nativeElement;
      inputElement.type =
        inputElement.type === 'password' ? 'text' : 'password';
    }
    if (inputField === 'repasswordInput') {
      const inputElement = this.repasswordInput.nativeElement;
      inputElement.type =
        inputElement.type === 'password' ? 'text' : 'password';
    }
  }

  getBuyerType(): string {
    if (this.selectedIam && this.selectedIam.value) {
      return this.selectedIam.value;
    }
  }

  toggleSubscribeCheckbox(event: any) {
    const checked = event.checked;
    this.subscribeCheckbox = checked;
  }

  toggleTermsAndConditions(event: any) {
    const checked = event.checked;
    this.termsAndConditions = checked;
  }

  onLanguageSelect(item: any) {
    this.selectedLanguages.push(item);
    this.updateSelectedLanguagesString();
  }

  onLanguageDeSelect(item: any) {
    this.selectedLanguages = this.selectedLanguages.filter(
      (lang) => lang.id !== item.id
    );
    this.updateSelectedLanguagesString();
  }

  updateSelectedLanguagesString() {
    this.selectedLanguagesString = this.selectedLanguages
      .map((lang) => lang.language)
      .join(', ');
  }

  onSelectHowDidYouHear(item): void {
    this.selectedHowDidYouHear = item;
    let processedValue: string;
    if (
      item.value.includes(`${this.builder_name} told me directly about Virtuo`)
    ) {
      processedValue = item.value.replace(`${this.builder_name}`, 'My builder');
    } else if (
      item.value.includes(
        `I got a message from ${this.builder_name} about Virtuo`
      )
    ) {
      processedValue = item.value.replace(`${this.builder_name}`, 'my builder');
    } else {
      processedValue = item.value;
    }
    this.selectedHowYouHear = { value: processedValue };
  }

  onSubmit() {
    const move_out_date = null;
    const possession_date = null;
    let possible_services = null;

    if (this.servicesList.some((service) => service.selected)) {
      possible_services = this.servicesList
        .filter((service) => service.selected)
        .map((service) => service.name)
        .join(', ');
    }

    const activation_data = {
      buyer_type: this.getBuyerType(),
      who_is_moving: this.details.who_is_moving
        ? this.details.who_is_moving
        : '',
      target_possession_date: possession_date
        ? possession_date.toISOString().split('T')[0]
        : '',
      selling_property: this.selectedAreYouSelling
        ? this.selectedAreYouSelling.value
        : '',
      move_out_date: move_out_date
        ? move_out_date.toISOString().split('T')[0]
        : '',
      possible_services: possible_services ? possible_services : '',
      preferred_language:
        this.selectedLanguagesString != '' ? this.selectedLanguagesString : '',
      preferred_contact: this.selectedPrefferedContactMedium
        ? this.selectedPrefferedContactMedium.value
        : '',
      awareness: this.selectedHowYouHear ? this.selectedHowYouHear.value : '',
      subscribe: this.subscribeCheckbox,
      referred_by: this.selectedReferredBy
        ? this.selectedReferredBy.value === 'Yes'
          ? this.details.referred_by
          : 'No'
        : '',
      referred_by_type: this.selectedReferredByType
        ? this.selectedReferredByType.value
        : '',
    };

    const new_password = this.password;
    const new_password_confirmation = this.repassword;
    const email = this.email;
    const new_user_token = this.new_user_token;
    const user_id = this.user.id;
    const body = {
      new_user_token,
      email,
      new_password,
      new_password_confirmation,
      user_id,
      activation_data,
      place_details: this.placeDetails,
    };

    this.dataService.clientOnboarding(body).subscribe((data: any) => {});
  }

  okMethod() {
    this.success = true;
  }

  textLink() {
    const body = {
      email: this.email,
      phone: this.text_phone,
      new_user_token: this.new_user_token,
    };
    this.dataService.sendAppLink(body).subscribe(
      (data: any) => {
        this.validation_error = '';
        this.success = true;
        swal({
          text: 'Text sent!',
        });
      },
      (err) => {
        this.validation_error = err.error.error;
      }
    );
  }

  signInToApp() {
    this.router.navigate(['login']);
  }
}
